import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useExport,
  usePermissions,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Card,
  Space,
  ShowButton,
  Form,
  Input,
  Button,
  Icons,
  ExportButton,
  Select,
  useSelect,
  DatePicker,
  ConfigProvider,
} from "@pankod/refine-antd";
import "dayjs/locale/pt-br";
import dayjs, { Dayjs } from "dayjs";

import { IHealthInsurer, ILeads } from "interfaces";
import { IMaskInput } from "react-imask";
import { useState } from "react";
import "./styles.css";

import "dayjs/locale/pt-br";
import ptBR from "antd/es/date-picker/locale/pt_BR";

dayjs.locale("pt-br");

interface ISearch {
  cpf: string;
  city: string;
  state: string;
  status: string | number;
  product: string | number;
  ctrl_number: string;
  cell_phone: string;
  created_start: string;
  created_end: string;
  updated_start: string;
  updated_end: string;
  payment_start: string;
  payment_end: string;
  bill_emission_start: string;
  bill_emission_end: string;
}

export const BudgetsList: React.FC<IResourceComponentsProps> = () => {
  const permissions = usePermissions();
  const [params, setParams] = useState<ISearch>();

  const PAGE_SIZE = 100;
  const FILTER_EQUALS_OPERATOR = "eq";
  const BASIC_ACCESS_PERMISSION = permissions.data === "REPORTS_BASIC";

  const STATUS_SIGO_LIST = [
    { id: 1, label: "Pendente", value: 1 },
    { id: 2, label: "Cancelado", value: 2 },
    { id: 3, label: "Aguardando Pagamento", value: 3 },
    { id: 4, label: "Devolvido", value: 4 },
    { id: 5, label: "Aguardando Auditoria Médica", value: 5 },
    { id: 6, label: "Aguardando Confirmação do Cliente", value: 6 },
    { id: 7, label: "Orçamento Validado", value: 7 },
    { id: 8, label: "Autorizado", value: 8 },
    { id: 9, label: "Processado", value: 9 },
    { id: 10, label: "Validado e Ausente de Crítica", value: 10 },
    { id: 11, label: "Em digitação pela Concessionária", value: 11 },
    { id: 12, label: "Assinado pelo Cliente", value: 12 },
    { id: 13, label: "Orçamento Recusado pelo Cliente", value: 13 },
    { id: 14, label: "Cancelado p/Falta de Pagamento (07 dias)", value: 14 },
    { id: 15, label: "Cancelado p/Decurso de Prazo (25 dias)", value: 15 },
    { id: 16, label: "Cancelamento p/Divergência na D.S", value: 16 },
    { id: 17, label: "Valido com dados divergentes", value: 17 },
    { id: 18, label: "Pago pelo cliente", value: 18 },
    { id: 19, label: "Pago ao vendedor", value: 19 },
    { id: 20, label: "Pendencia medica", value: 20 },
  ];

  const STATES_LIST_BRAZIL = [
    { id: "AC", value: "AC", name: "Acre", label: "AC" },
    { id: "AL", value: "AL", name: "Alagoas", label: "AL" },
    { id: "AP", value: "AP", name: "Amapá", label: "AP" },
    { id: "AM", value: "AM", name: "Amazonas", label: "AM" },
    { id: "BA", value: "BA", name: "Bahia", label: "BA" },
    { id: "CE", value: "CE", name: "Ceará", label: "CE" },
    { id: "DF", value: "DF", name: "Distrito Federal", label: "DF" },
    { id: "ES", value: "ES", name: "Espírito Santo", label: "ES" },
    { id: "GO", value: "GO", name: "Goiás", label: "GO" },
    { id: "MA", value: "MA", name: "Maranhão", label: "MA" },
    { id: "MT", value: "MT", name: "Mato Grosso", label: "MT" },
    { id: "MS", value: "MS", name: "Mato Grosso do Sul", label: "MS" },
    { id: "MG", value: "MG", name: "Minas Gerais", label: "MG" },
    { id: "PA", value: "PA", name: "Pará", label: "PA" },
    { id: "PB", value: "PB", name: "Paraíba", label: "PB" },
    { id: "PR", value: "PR", name: "Paraná", label: "PR" },
    { id: "PE", value: "PE", name: "Pernambuco", label: "PE" },
    { id: "PI", value: "PI", name: "Piauí", label: "PI" },
    { id: "RJ", value: "RJ", name: "Rio de Janeiro", label: "RJ" },
    { id: "RN", value: "RN", name: "Rio Grande do Norte", label: "RN" },
    { id: "RS", value: "RS", name: "Rio Grande do Sul", label: "RS" },
    { id: "RO", value: "RO", name: "Rondônia", label: "RO" },
    { id: "RR", value: "RR", name: "Roraima", label: "RR" },
    { id: "SC", value: "SC", name: "Santa Catarina", label: "SC" },
    { id: "SP", value: "SP", name: "São Paulo", label: "SP" },
    { id: "SE", value: "SE", name: "Sergipe", label: "SE" },
    { id: "TO", value: "TO", name: "Tocantins", label: "TO" },
  ];

  const formatDate = (date: Dayjs | string, isStart: boolean) => {
    if (!date) {
      return null;
    }

    if (isStart) {
      return dayjs(date).format("YYYY-MM-DD 00:00:00");
    }
    return dayjs(date).format("YYYY-MM-DD 23:59:59");
  };

  const handleSearch = (values: any): CrudFilters => {
    return [
      {
        field: "cpf",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.cpf ?? null,
      },
      {
        field: "city",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.city ?? null,
      },
      {
        field: "state",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.state ?? null,
      },
      {
        field: "status",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.status ?? null,
      },
      {
        field: "ctrl_number",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.ctrl_number ?? null,
      },
      {
        field: "cell_phone",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.cell_phone ?? null,
      },
      {
        field: "payment_start",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.payment_start
          ? formatDate(values.payment_start, true)
          : null,
      },
      {
        field: "payment_end",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.payment_end
          ? formatDate(values.payment_end, false)
          : null,
      },
      {
        field: "created_start",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.created_start
          ? formatDate(values.created_start, true)
          : null,
      },
      {
        field: "created_end",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.created_end
          ? formatDate(values.created_end, false)
          : null,
      },
      {
        field: "health_insurer",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.health_insurer ?? null,
      },
      {
        field: "branch_code",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.branch_code ?? null,
      },
      {
        field: "product",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.product ?? null,
      },
      {
        field: "friendly_plan_name",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.friendly_plan_name ?? null,
      },
      {
        field: "ans_code",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.ans_code ?? null,
      },
      {
        field: "budget_number",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.budget_number ?? null,
      },
      {
        field: "updated_start",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.updated_start
          ? formatDate(values.updated_start, true)
          : null,
      },
      {
        field: "updated_end",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.updated_end
          ? formatDate(values.updated_end, false)
          : null,
      },
      {
        field: "bill_emission_start",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.bill_emission_start
          ? formatDate(values.bill_emission_start, true)
          : null,
      },
      {
        field: "bill_emission_end",
        operator: FILTER_EQUALS_OPERATOR,
        value: values?.bill_emission_end
          ? formatDate(values.bill_emission_end, false)
          : null,
      },
    ];
  };

  const mapExportData = (item: ILeads) => ({
    data: item?.created_at ?? "",
    data_atualizacao: item?.updated_at ?? "",
    nome: item?.name ?? "",
    cpf: item?.cpf ?? "",
    city: item?.city ?? "",
    state: item?.state ?? "",
    status: item?.status ?? "",
    statusName:
      STATUS_SIGO_LIST.find((status) => status.id === item?.status)?.label ||
      "",
    telefone: item?.cell_phone ?? "",
    email: item?.email ?? "",
    operadora: item?.health_insurer ?? "",
    cidade: item?.city ?? "",
    uf: item?.state ?? "",
    nu_controle: item?.control_number ?? "",
    produto: item?.product ?? "",
    num_vidas: item?.dependents ?? "",
    ultima_tela: item?.last_screen ?? "",
    origem: item?.origin ?? "",
    data_pagamento: item?.payment_details?.PaymentDate ?? "",
    data_emissao_boleto: item?.payment_details?.BillEmissionDate ?? "",
  });

  const getValidFilters = (filters: CrudFilters): CrudFilters => {
    return filters.filter((filter) => filter.value !== "");
  };

  const exportFilters: CrudFilters = [
    { field: "cpf", operator: FILTER_EQUALS_OPERATOR, value: params?.cpf },
    { field: "city", operator: FILTER_EQUALS_OPERATOR, value: params?.city },
    { field: "state", operator: FILTER_EQUALS_OPERATOR, value: params?.state },
    {
      field: "status",
      operator: FILTER_EQUALS_OPERATOR,
      value: params?.status,
    },
    {
      field: "ctrl_number",
      operator: FILTER_EQUALS_OPERATOR,
      value: params?.ctrl_number,
    },
    {
      field: "cell_phone",
      operator: FILTER_EQUALS_OPERATOR,
      value: params?.cell_phone,
    },
    {
      field: "created_start",
      operator: FILTER_EQUALS_OPERATOR,
      value: params?.created_start,
    },
    {
      field: "created_end",
      operator: FILTER_EQUALS_OPERATOR,
      value: params?.created_end,
    },
    {
      field: "updated_start",
      operator: FILTER_EQUALS_OPERATOR,
      value: params?.updated_start,
    },
    {
      field: "updated_end",
      operator: FILTER_EQUALS_OPERATOR,
      value: params?.updated_end,
    },
    {
      field: "payment_start",
      operator: FILTER_EQUALS_OPERATOR,
      value: params?.payment_start,
    },
    {
      field: "payment_end",
      operator: FILTER_EQUALS_OPERATOR,
      value: params?.payment_end,
    },
  ];

  const { tableProps, searchFormProps, sorter } = useTable<ILeads, HttpError>({
    resource: "v2/budget",
    metaData: {
      usePost: true,
      query: {
        customParam: "value",
      },
    },
    initialFilter: [
      {
        field: "created_start",
        operator: FILTER_EQUALS_OPERATOR,
        value: null,
      },
      {
        field: "created_end",
        operator: FILTER_EQUALS_OPERATOR,
        value: null,
      },
    ],
    onSearch: handleSearch,
    initialCurrent: 1,
    initialPageSize: PAGE_SIZE,
    hasPagination: true,
  });

  const { triggerExport, isLoading: exportLoading } = useExport<ILeads>({
    resourceName: "v2/budget",
    metaData: {
      usePost: true,
    },
    filters: getValidFilters(exportFilters),
    mapData: mapExportData,
    pageSize: PAGE_SIZE,
  });

  const { selectProps: selectPropsBranchOffice } = useSelect<IHealthInsurer>({
    resource: "v1/branch-office",
    optionLabel: "AreaDescription",
    optionValue: "BranchOfficeId",
    hasPagination: true,
  });

  const { selectProps: selecthealthInsurer } = useSelect<IHealthInsurer>({
    resource: "v1/health-insurer",
    optionLabel: "Description",
    optionValue: "HealthInsurerId",
    hasPagination: true,
  });

  const handleClearFilters = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
    setParams(undefined);
  };

  const handleSubmitForm = () => {
    const cpf = searchFormProps.form?.getFieldValue("cpf");

    const city = searchFormProps.form?.getFieldValue("city");

    const state = searchFormProps.form?.getFieldValue("state");

    const status = searchFormProps.form?.getFieldValue("status");

    const controlNumber = searchFormProps.form?.getFieldValue("ctrl_number");

    const cellNumber = searchFormProps.form?.getFieldValue("cell_phone");

    const productNumber = searchFormProps.form?.getFieldValue("product");

    const initialDate = searchFormProps.form?.getFieldValue("created_start")
      ? dayjs(searchFormProps.form?.getFieldValue("created_start")).format(
          "YYYY-MM-DD 00:00:00"
        )
      : "";

    const finalDate = searchFormProps.form?.getFieldValue("created_end")
      ? dayjs(searchFormProps.form?.getFieldValue("created_end")).format(
          "YYYY-MM-DD 23:59:59"
        )
      : "";

    const paymentInitialDate = searchFormProps.form?.getFieldValue(
      "payment_start"
    )
      ? dayjs(searchFormProps.form?.getFieldValue("payment_start")).format(
          "YYYY-MM-DD 00:00:00"
        )
      : "";

    const paymentFinalDate = searchFormProps.form?.getFieldValue("payment_end")
      ? dayjs(searchFormProps.form?.getFieldValue("payment_end")).format(
          "YYYY-MM-DD 23:59:59"
        )
      : "";

    const billEmissionStartDate = searchFormProps.form?.getFieldValue(
      "bill_emission_start"
    )
      ? dayjs(
          searchFormProps.form?.getFieldValue("bill_emission_start")
        ).format("YYYY-MM-DD 00:00:00")
      : "";

    const billEmissionFinalDate = searchFormProps.form?.getFieldValue(
      "bill_emission_end"
    )
      ? dayjs(searchFormProps.form?.getFieldValue("bill_emission_end")).format(
          "YYYY-MM-DD 23:59:59"
        )
      : "";

    const updatedStartDate = searchFormProps.form?.getFieldValue(
      "updated_start"
    )
      ? dayjs(searchFormProps.form?.getFieldValue("updated_start")).format(
          "YYYY-MM-DD 00:00:00"
        )
      : "";

    const updatedFinalDate = searchFormProps.form?.getFieldValue("updated_end")
      ? dayjs(searchFormProps.form?.getFieldValue("updated_end")).format(
          "YYYY-MM-DD 23:59:59"
        )
      : "";

    setParams({
      cpf,
      city,
      state,
      status,
      product: productNumber,
      ctrl_number: controlNumber,
      cell_phone: cellNumber,
      created_start: initialDate,
      created_end: finalDate,
      payment_start: paymentInitialDate,
      payment_end: paymentFinalDate,
      updated_start: updatedStartDate,
      updated_end: updatedFinalDate,
      bill_emission_start: billEmissionStartDate,
      bill_emission_end: billEmissionFinalDate,
    });
    searchFormProps.form?.submit();
  };

  return (
    <List
      headerProps={{
        extra: (
          <Space>
            <ExportButton onClick={triggerExport} loading={exportLoading} />
          </Space>
        ),
      }}>
      <Card title="Busca">
        <ConfigProvider>
          <Form {...searchFormProps}>
            <div className="filter-container">
              <div className="filters-inputs">
                <div className="filter-fields-group-item">
                  <label>CPF:</label>

                  <Form.Item name="cpf">
                    <IMaskInput
                      mask={"000.000.000-00"}
                      className="input-custom ant-input"
                    />
                  </Form.Item>
                </div>

                <div className="filter-fields-group-item">
                  <label># Controle:</label>
                  <Form.Item
                    name="ctrl_number"
                    className="filter-fields-group-item">
                    <Input className="input-custom ant-input" />
                  </Form.Item>
                </div>

                <div className="filter-fields-group-item">
                  <label>Telefone:</label>
                  <Form.Item name="cell_phone">
                    <IMaskInput
                      mask="(00)00000-0000"
                      className="input-custom  ant-input"
                    />
                  </Form.Item>
                </div>

                <div className="filter-fields-group-item">
                  <label># Produto:</label>
                  <Form.Item
                    name="product"
                    className="filter-fields-group-item">
                    <Input className="input-custom" />
                  </Form.Item>
                </div>

                <div className="filter-fields-group-item">
                  <label>Codigo ANS:</label>
                  <Form.Item name="ans_code">
                    <Input className="input-custom" />
                  </Form.Item>
                </div>

                <div className="filter-fields-group-item">
                  <label>Numero Orçamento:</label>
                  <Form.Item name="budget_number">
                    <Input className="input-custom" />
                  </Form.Item>
                </div>

                <div className="filter-fields-group-item">
                  <label>Status SIGO:</label>
                  <Form.Item name="status">
                    <Select
                      options={STATUS_SIGO_LIST}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </div>

                <div className="filter-fields-group-item">
                  <label>Operadora:</label>
                  <Form.Item name="health_insurer_id">
                    <Select
                      {...selecthealthInsurer}
                      options={[
                        { label: "Todas", value: null },
                        ...selecthealthInsurer.options!,
                      ]}
                    />
                  </Form.Item>
                </div>

                <div className="filter-fields-group-item">
                  <label>Filial:</label>
                  <Form.Item name="branch_code">
                    <Select
                      {...selectPropsBranchOffice}
                      options={[
                        { label: "Todas", value: null },
                        ...selectPropsBranchOffice.options!,
                      ]}
                    />
                  </Form.Item>
                </div>

                <div className="filter-fields-group-item">
                  <label>Estado:</label>
                  <Form.Item name="state" className="input-select">
                    <Select
                      options={[
                        { label: "Todos", value: null },
                        ...STATES_LIST_BRAZIL,
                      ]}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </div>

                <div className="filter-fields-group-item">
                  <label>Cidade:</label>
                  <Form.Item name="city">
                    <Input className="input-custom" />
                  </Form.Item>
                </div>

                <div className="filter-fields-group-item">
                  <div className="group-date">
                    <div className="group-label">
                      <label className="ant-col ant-form-item-label">
                        Data de Criação:
                      </label>
                    </div>
                    <div className="group-fields">
                      <Form.Item
                        name="created_start"
                        getValueProps={(value) => ({
                          value: value ? dayjs(value).hour(12) : "",
                        })}
                        className="date-field">
                        <DatePicker
                          format="DD/MM/YYYY"
                          locale={ptBR}
                          disabledDate={(current) =>
                            current &&
                            current.isAfter(
                              dayjs(
                                searchFormProps?.form?.getFieldValue(
                                  "created_end"
                                )
                              ).startOf("day"),
                              "day"
                            )
                          }
                          placeholder="Início"
                        />
                      </Form.Item>
                      <Form.Item
                        name="created_end"
                        getValueProps={(value) => ({
                          value: value ? dayjs(value).hour(12) : "",
                        })}
                        className="date-field">
                        <DatePicker
                          format="DD/MM/YYYY"
                          locale={ptBR}
                          disabledDate={(current) =>
                            current &&
                            searchFormProps?.form?.getFieldValue(
                              "created_start"
                            )
                              ? current.isBefore(
                                  dayjs(
                                    searchFormProps?.form?.getFieldValue(
                                      "created_start"
                                    )
                                  ).startOf("day"),
                                  "day"
                                )
                              : false
                          }
                          placeholder="Fim"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="filter-fields-group-item">
                  <div className="group-date">
                    <div className="group-label">
                      <label className="ant-col ant-form-item-label">
                        Data da Última Atualização:
                      </label>
                    </div>
                    <div className="group-fields">
                      <Form.Item
                        name="updated_start"
                        getValueProps={(value) => ({
                          value: value ? dayjs(value).hour(12) : "",
                        })}
                        className="date-field">
                        <DatePicker
                          format="DD/MM/YYYY"
                          locale={ptBR}
                          disabledDate={(current) =>
                            current &&
                            current.isAfter(
                              dayjs(
                                searchFormProps?.form?.getFieldValue(
                                  "updated_end"
                                )
                              ).startOf("day"),
                              "day"
                            )
                          }
                          placeholder="Início"
                        />
                      </Form.Item>
                      <Form.Item
                        name="updated_end"
                        getValueProps={(value) => ({
                          value: value ? dayjs(value).hour(12) : "",
                        })}
                        className="date-field">
                        <DatePicker
                          format="DD/MM/YYYY"
                          locale={ptBR}
                          disabledDate={(current) =>
                            current &&
                            searchFormProps?.form?.getFieldValue(
                              "updated_start"
                            )
                              ? current.isBefore(
                                  dayjs(
                                    searchFormProps?.form?.getFieldValue(
                                      "updated_start"
                                    )
                                  ).startOf("day"),
                                  "day"
                                )
                              : false
                          }
                          placeholder="Fim"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="filter-fields-group-item">
                  <div className="group-date">
                    <div className="group-label">
                      <label className="ant-col ant-form-item-label">
                        Data de Pagamento:
                      </label>
                    </div>
                    <div className="group-fields">
                      <Form.Item
                        name="payment_start"
                        getValueProps={(value) => ({
                          value: value ? dayjs(value).hour(12) : "",
                        })}
                        className="date-field">
                        <DatePicker
                          format="DD/MM/YYYY"
                          locale={ptBR}
                          disabledDate={(current) =>
                            current &&
                            current.isAfter(
                              dayjs(
                                searchFormProps?.form?.getFieldValue(
                                  "payment_end"
                                )
                              ).startOf("day"),
                              "day"
                            )
                          }
                          placeholder="Início"
                        />
                      </Form.Item>
                      <Form.Item
                        name="payment_end"
                        getValueProps={(value) => ({
                          value: value ? dayjs(value).hour(12) : "",
                        })}
                        className="date-field">
                        <DatePicker
                          format="DD/MM/YYYY"
                          locale={ptBR}
                          disabledDate={(current) =>
                            current &&
                            searchFormProps?.form?.getFieldValue(
                              "payment_start"
                            )
                              ? current.isBefore(
                                  dayjs(
                                    searchFormProps?.form?.getFieldValue(
                                      "payment_start"
                                    )
                                  ).startOf("day"),
                                  "day"
                                )
                              : false
                          }
                          placeholder="Fim"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="filter-fields-group-item">
                  <div className="group-date">
                    <div className="group-label">
                      <label className="ant-col ant-form-item-label">
                        Data de Emissão de Boleto:
                      </label>
                    </div>
                    <div className="group-fields">
                      <Form.Item
                        name="bill_emission_start"
                        getValueProps={(value) => ({
                          value: value ? dayjs(value).hour(12) : "",
                        })}
                        className="date-field">
                        <DatePicker
                          format="DD/MM/YYYY"
                          locale={ptBR}
                          disabledDate={(current) =>
                            current &&
                            current.isAfter(
                              dayjs(
                                searchFormProps?.form?.getFieldValue(
                                  "bill_emission_end"
                                )
                              ).startOf("day"),
                              "day"
                            )
                          }
                          placeholder="Início"
                        />
                      </Form.Item>
                      <Form.Item
                        name="bill_emission_end"
                        getValueProps={(value) => ({
                          value: value ? dayjs(value).hour(12) : "",
                        })}
                        className="date-field">
                        <DatePicker
                          format="DD/MM/YYYY"
                          locale={ptBR}
                          disabledDate={(current) =>
                            current &&
                            searchFormProps?.form?.getFieldValue(
                              "bill_emission_start"
                            )
                              ? current.isBefore(
                                  dayjs(
                                    searchFormProps?.form?.getFieldValue(
                                      "bill_emission_start"
                                    )
                                  ).startOf("day"),
                                  "day"
                                )
                              : false
                          }
                          placeholder="Fim"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttons-container">
                <Button
                  type="default"
                  icon={<Icons.ClearOutlined />}
                  className="ant-btn ant-btn-default"
                  onClick={handleClearFilters}>
                  Limpar
                </Button>
                <Button
                  type="primary"
                  icon={<Icons.SearchOutlined />}
                  className="ant-btn ant-btn-primary"
                  onClick={handleSubmitForm}>
                  Aplicar
                </Button>
              </div>
            </div>
          </Form>
        </ConfigProvider>
      </Card>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          dataIndex="cpf"
          key="cpf"
          title="CPF"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("cpf", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("Name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="cell_phone"
          key="cell_phone"
          title="Telefone"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("cell_phone", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title="E-Mail"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("email", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="health_insurer_name"
          key="health_insurer_name"
          title="Operadora"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("health_insurer_name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title="Status SIGO"
          render={(value) => {
            const statusName =
              STATUS_SIGO_LIST.find((status) => status.id === value)?.label ||
              "";
            return <TextField value={statusName} />;
          }}
          defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
        />
        <Table.Column<ILeads>
          dataIndex="city"
          key="city"
          title="Cidade"
          render={(value, record) => (
            <TextField value={`${value} - ${record.state}`} />
          )}
          defaultSortOrder={getDefaultSortOrder("city", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="control_number"
          key="control_number"
          title="Nº de Controle"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("control_number", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="product_name"
          key="product_name"
          title="Produto"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("product_name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="dependents"
          key="dependents"
          title="Vidas"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("dependents", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="last_screen"
          key="last_screen"
          title="Última Tela"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("last_screen", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="origin"
          key="origin"
          title="Origem"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("origin", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title="Data de Criação"
          render={(value) => (
            <TextField
              value={value ? dayjs(value).format("DD/MM/YYYY HH:mm") : "-"}
            />
          )}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="updated_at"
          key="updated_at"
          title="Data da Última Atualização"
          render={(value) => (
            <TextField
              value={value ? dayjs(value).format("DD/MM/YYYY HH:mm") : "-"}
            />
          )}
          defaultSortOrder={getDefaultSortOrder("updated_at", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="payment_details.PaymentDate"
          key="payment_details.PaymentDate"
          title="Data de Pgto."
          render={(value: any, record: any) => (
            <TextField
              value={
                record?.payment_details?.PaymentDate
                  ? dayjs(record?.payment_details?.PaymentDate).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : "-"
              }
            />
          )}
          defaultSortOrder={getDefaultSortOrder(
            "payment_details.BillEmissionDate",
            sorter
          )}
          sorter
        />
        <Table.Column
          dataIndex="payment_details.BillEmissionDate"
          key="payment_details.BillEmissionDate"
          title="Data da Emissão do Boleto"
          render={(value: any, record: any) => (
            <TextField
              value={
                record?.payment_details?.BillEmissionDate
                  ? dayjs(record?.payment_details?.BillEmissionDate).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : "-"
              }
            />
          )}
          defaultSortOrder={getDefaultSortOrder(
            "payment_details.BillEmissionDate",
            sorter
          )}
          sorter
        />
        <Table.Column<ILeads>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <ShowButton
                hidden={BASIC_ACCESS_PERMISSION}
                hideText
                size="small"
                recordItemId={record._id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
