import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

import { IHolidays } from "interfaces";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import React from "react";

dayjs.locale("pt-br");

export const HolidaysShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IHolidays>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { Title, Text } = Typography;
  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Descrição</Title>
      <Text>{record?.description}</Text>

      <Title level={5}>Data</Title>
      <Text>{dayjs(record?.date).format("DD/MM/YYYY")}</Text>
    </Show>
  );
};
