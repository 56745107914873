import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  Card,
  Form,
  Button,
  useSelect,
  Icons,
  Select,
  ImageField,
} from "@pankod/refine-antd";
import { IBranchOfficesSearch, IPresencePoints } from "interfaces";

export const PresencePointsList: React.FC<IResourceComponentsProps> = () => {
  const { selectProps } = useSelect<IBranchOfficesSearch>({
    resource: "v1/branch-office/distinct-ids",
    optionLabel: "branch_id",
    optionValue: "branch_id",
    hasPagination: false,
  });

  const { tableProps, searchFormProps, sorter } = useTable<IPresencePoints>({
    initialSorter: [
      {
        field: "branch_id",
        order: "asc",
      },
    ],
    onSearch: (values: any) => {
      return [
        {
          field: "branch_id",
          operator: "eq",
          value: values.branch_id,
        },
      ];
    },
  });

  return (
    <List>
      <Card title="Busca">
        <Form {...searchFormProps} layout="inline">
          <Space>
            <Form.Item name="branch_id" label="Código da Filial:">
              <Select {...selectProps} style={{ width: "80px" }} />
            </Form.Item>
            <Button
              size="small"
              type="primary"
              icon={<Icons.SearchOutlined />}
              onClick={() => {
                searchFormProps.form?.submit();
              }}>
              Buscar
            </Button>
          </Space>
        </Form>
      </Card>
      <Table {...tableProps} rowKey="_id">
        <Table.Column<IPresencePoints>
          title="Imagem"
          dataIndex="image"
          render={(value, record) =>
            value ? (
              <ImageField
                value={value}
                title={record?.facility_name}
                width={100}
              />
            ) : (
              <TextField value="" />
            )
          }
          width="120px"
        />
        <Table.Column
          dataIndex="facility_name"
          key="facility_name"
          title="Nome"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("facility_name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="type"
          key="type"
          title="Tipo"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("typo", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="branch_id"
          key="branch_id"
          title="Codigo da Filial"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("branch_id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="address"
          key="address"
          title="Endereço"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("address", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="latitude"
          key="latitude"
          title="Latitude"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("latitude", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="longitude"
          key="longitude"
          title="Longitude"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("longitude", sorter)}
          sorter
        />

        <Table.Column<IPresencePoints>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
