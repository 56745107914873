import { DatePicker, Form, Input } from "@pankod/refine-antd";
import { MyFormProps } from ".";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import ptBR from "antd/es/date-picker/locale/pt_BR";

dayjs.locale("pt-br");

export const HolidaysForm: React.FC<MyFormProps> = ({ formProps }) => {
  const { form } = formProps;

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item
        label="Descrição"
        name="description"
        rules={[
          {
            required: true,
          },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Data"
        name="date"
        getValueProps={(value) => ({
          value: value ? dayjs(value).hour(12) : "",
        })}
        rules={[{ required: true }]}>
        <DatePicker
          format="DD/MM/YYYY"
          locale={ptBR}
          placeholder="Data"
          onChange={(date) => {
            if (date) {
              form?.setFieldsValue({
                date: date.isValid() ? date.format("YYYY-MM-DD") : "",
              });
            }
          }}
        />
      </Form.Item>
    </Form>
  );
};
