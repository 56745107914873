import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  BooleanField,
  Icons,
  Button,
  Card,
  Form,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import { IBranchOffices, IBranchOfficesSearch } from "interfaces";
import { BFF_KEY } from "environment";

export const BranchOfficesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IBranchOffices>({
    initialSorter: [
      {
        field: "BranchOfficeId",
        order: "asc",
      },
    ],
    initialCurrent: 1,
    initialPageSize: 100,
    hasPagination: true,
    onSearch: (values: any) => {
      return [
        {
          field: "branch_id",
          operator: "eq",
          value: values.branch_id,
        }
      ];
    },
  });

  const { selectProps } = useSelect<IBranchOfficesSearch>({
    resource: "v1/branch-office/distinct-ids",
    optionLabel: "branch_id",
    optionValue: "branch_id",
    hasPagination: false
  });

  const apiUrl = useApiUrl();
  const handleHidden = async (id: string, Hidden: boolean) => {
    await fetch(`${apiUrl}/v1/branch-office/hidden/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': BFF_KEY,
      },
      body: JSON.stringify({ Hidden: Hidden }),
    }).then(data => data.json());
  };

  return (
    <>
      <Card title="Busca">
        <Form {...searchFormProps} layout="inline">
          <Space>
            <Form.Item name="branch_id" label="Código da Filial:">
              <Select {...selectProps} style={{ width: "80px" }} />
            </Form.Item>
            <Button
              size="small"
              type="primary"
              icon={<Icons.SearchOutlined />}
              onClick={searchFormProps.form?.submit}
            >
              Buscar
            </Button>
          </Space>
        </Form>
      </Card>
      <List canCreate={true}>
        <Table {...tableProps} rowKey="AreaId">
          <Table.Column
            dataIndex="AreaId"
            key="AreaId"
            title="Id área"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("AreaId", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="AreaDescription"
            key="AreaDescription"
            title="Nome da área"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("AreaDescription", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="Uf"
            key="Uf"
            title="Estado"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("Uf", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="BranchOfficeId"
            key="BranchOfficeId"
            title="Id filial"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("BranchOfficeId", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="Hidden"
            key="Hidden"
            title="Oculto?"
            render={(value) =>
              <BooleanField
                value={value === true}
                trueIcon={<Icons.MinusOutlined />}
                falseIcon={<Icons.PlusOutlined />}
                valueLabelTrue="Oculto"
                valueLabelFalse="Não Oculto"
              />}
          />
          <Table.Column<IBranchOffices>
            title="Ações"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <Button
                  size="small"
                  icon={record.Hidden ? <Icons.PlusOutlined /> : <Icons.MinusOutlined />}
                  onClick={() => {
                    handleHidden(record.AreaId.toString(), !record.Hidden).then(() => {
                      tableQueryResult.refetch();
                    });
                  }}
                >{record.Hidden ? "On" : "Off"}</Button>
                <EditButton hideText size="small" recordItemId={record.AreaId} />
                <ShowButton hideText size="small" recordItemId={record.AreaId} />
                <DeleteButton hidden hideText size="small" recordItemId={record.AreaId} />
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  );
};
